/* ==========================================================================
/* ===== Typography  ===== */
/*========================================================================== */

body {
	font-family: $FontSecondary;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $FontPrimary;
}

h1 {
	font-size: 1.875rem;
}

p { color: $textColor; }

a { color: #e6810d; }